<template>
  <v-dialog
    v-model="updateSponsorModal"
    width="800px"
  >
    <v-card class="py-5 pb-5">
      <v-card-title>
        <div class="text-center text-h5" style="width: 100%">
          Editar Patrocinador
        </div>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          class="mx-5 mt-5"
          @submit.prevent="updateSponsor()"
        >
          <v-text-field
            v-model="sponsor.name"
            :rules="[rules.required]"
            outlined
            required
            label="Nome *"
          />

          <image-uploader
            title="Clique aqui para enviar a logo do patrocinador"
            subtitle="Tamanho recomendado: 450 de largura por 200 pixels de altura"
            :saving="saving"
            :savedImage="olderImage"
            :errorImage="imageOffIcon"
            :fullWidth="true"
            previewWidth="450px"
            previewHeight="200px"
            @img-uploaded="handleImage"
            @img-removed="handleRemoveImage"
          />
        </v-form>
      </v-card-text>

      <v-card-actions class="mt-5 mx-5">
        <v-row justify="center">
          <v-btn
            class="py-4"
            color="primary"
            type="submit"
            :disabled="saving"
            @click="updateSponsor()"
          >
            <v-progress-circular
              v-if="saving"
              indeterminate
              size="25"
              width="3"
              class="px-6"
              color="white"
            />
            <span
              v-else
              class="px-6"
            >
              Salvar
            </span>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    ImageUploader: () => import('@/components/ImageUploader.vue')
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      saving: false,
      valid: true,
      image: null,
      olderImage: null,
      imageOffIcon,
      sponsor: {
        name: '',
        imageType: undefined
      },
      rules: {
        required: value => !!value || 'Este campo é obrigatório.'
      }
    }
  },
  computed: {
    ...mapState(['sponsors']),
    updateSponsorModal: {
      get () {
        return this.$store.state.updateSponsorModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateSponsorModal',
          value: val
        })
      }
    }
  },
  watch: {
    updateSponsorModal (val) {
      if (this.id) {
        const sponsor = this.sponsors.filter((item) => item._id === this.id)[0]
        this.sponsor = sponsor
        this.olderImage = this.sponsor.image
      }

      if (!val) {
        this.sponsor = {
          name: '',
          imageType: undefined
        }
      }
    }
  },
  methods: {
    updateSponsor () {
      if (this.$refs.form.validate()) {
        if (this.olderImage || this.sponsor.imageType) {
          this.saving = true
          this.$http.put(`/sponsors/${this.id}`, {
            name: this.sponsor.name,
            ...(this.sponsor.imageType && { imageType: this.sponsor.imageType })
          })
            .then((res) => {
              const { data } = res
              if (data.putUrl) {
                this.uploadImage(data.putUrl, this.image, this.success, this.error)
              } else {
                this.success()
              }
            })
            .catch((err) => {
              this.saving = false
              this.$toast.error(err.response.data.message)
            })
        } else {
          this.$toast.error('É necessário enviar a logo do patrocinador.')
        }
      }
    },
    success () {
      this.saving = false
      this.updateSponsorModal = false
      this.$emit('updated')
      this.$toast.success('Cadastro atualizado com sucesso.')
    },
    error () {
      this.saving = false
      this.updateSponsorModal = false
      this.$toast.error('Algo de errado aconteceu ao salvar a imagem.')
    },
    handleImage (file) {
      this.sponsor.imageType = file.type
      this.image = file
    },
    handleRemoveImage () {
      this.sponsor.imageType = undefined
      this.image = null
      this.olderImage = null
    }
  }
}
</script>
